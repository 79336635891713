import store from '../store'

function treatError (error) {
  if (error.response) {
    const { status, statusText } = error.response
    error.status = status
    error.statusText = statusText
  }
  return error
}

const Auth = httpClient => ({
  login: async ({ email, password }) => {
    try {
      const { data } = await httpClient.post('/auth/login', { email, password })
      if (data) store.commit('setAdmin', data)
    } catch (error) {
      store.commit('setAdmin')
      throw treatError(error)
    }
  },

  check: async () => {
    try {
      const { data } = await httpClient.get('/auth/check')
      data ? store.commit('setAdmin', data) : store.commit('setAdmin', false)
    } catch (error) {
      throw treatError(error)
    }
  },

  logout: async () => {
    try {
      await httpClient.post('/auth/logout')
      store.commit('setAdmin')
    } catch (error) {
      throw treatError(error)
    }
  }
})

export default Auth
