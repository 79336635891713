import axios from 'axios'
import Auth from './Auth'
import Cost from './Cost'

let baseURL

if (process.env.NODE_ENV === 'production') {
  baseURL = 'https://veturi.pauloalexandre.com/api'
} else {
  baseURL = `http://${location.hostname}:8388`
}

const httpClient = axios.create({ baseURL, withCredentials: true })

// httpClient.interceptors.request.use((config) => {
//   return config
// })

httpClient.interceptors.response.use(
  response => response,
  error => {
    switch (error.message) {
      case 'Network Error':
        error.message = 'Falha na comunicação com o servidor'
        break
      case 'Request failed with status code 401':
        error.message = 'Falha na autenticação'
        break
    }
    throw error
  }
)

export const auth = Auth(httpClient)

export const cost = Cost(httpClient)
