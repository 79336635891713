import { createRouter, createWebHistory, START_LOCATION } from 'vue-router'
import store from '../store'

const Login = () => import('../views/Login')
const Home = () => import('../views/Home')
const Costs = () => import('../views/Costs')
const CostDetail = () => import('../views/Costs/CostDetail')
const Offline = () => import('../views/Offline')
const NotFound = () => import('../views/NotFound')

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: { requiresAuth: false }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/costs',
    name: 'Custos',
    component: Costs,
    meta: { requiresAuth: true }
  },
  {
    path: '/costs/:name',
    component: CostDetail,
    meta: { requiresAuth: true }
  },
  {
    path: '/offline',
    name: 'Offline',
    component: Offline,
    meta: { requiresAuth: false }
  },
  {
    path: '/not-found',
    name: 'Not Found',
    component: NotFound,
    meta: { requiresAuth: false }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'Not Found' }
  }
]

const router = createRouter({
  history: createWebHistory('/'),
  routes
})

function checkAdmin () {
  return new Promise((resolve, reject) => {
    if (store.state.admin === null) {
      const unwatch = store.watch(
        async (state, getters) => store.state.admin,
        (value, oldValue) => { unwatch(); resolve(value) }
      )
    } else {
      resolve(store.state.admin)
    }
  })
}

async function redirectIfNoAuth (to, from, next) {
  // console.log({ from: from.path, to: to.path })
  if (from === START_LOCATION) {
    store.commit('setInitialPath', to.path)
  }

  const isAdmin = await checkAdmin()

  if (!isAdmin && to.meta.requiresAuth) {
    next({ name: 'Login' })
    return
  }

  if (isAdmin && to.path === '/') {
    router.push({ name: 'Home' })
  }

  next()
}

router.beforeEach(redirectIfNoAuth)

export default router
