const Cost = httpClient => ({
  getCostsNames: async () => {
    const { data } = await httpClient.get('/data/costs')
    return data
  },
  readCost: async (name) => {
    const { data } = await httpClient.get(`/data/costs/${name}`)
    return data
  },
  updateCost: async (name, cost) => {
    try {
      const { data } = await httpClient.put(`/data/costs/${name}/${cost.id}`, cost)
      return data
    } catch (error) {
      console.error({ error })
    }
  },
  createCost: async (name, cost) => {
    try {
      const { data } = await httpClient.post(`/data/costs/${name}`, cost)
      return data
    } catch (error) {
      console.error({ error })
    }
  },
  deleteCost: async (name, id) => {
    try {
      const { data } = await httpClient.delete(`/data/costs/${name}/${id}`)
      return data
    } catch (error) {
      console.error({ error })
    }
  }
})

export default Cost
