import { createStore, createLogger } from 'vuex'
import { auth, cost } from '../services'
import router from '../router'

const debug = process.env.NODE_ENV !== 'production'

const getName = (costsNames, table) => {
  const cost = costsNames.find(cost => cost.table === table)
  return cost ? cost.name : ''
}

const store = createStore({
  strict: debug,
  plugins: debug ? [createLogger()] : [],
  state () {
    return {
      initialPath: null,
      admin: null,
      costsNames: [],
      costs: []
    }
  },

  mutations: {
    setInitialPath (state, payload) {
      state.initialPath = payload
    },

    setAdmin (state, payload) {
      state.admin = payload
      if (!payload) {
        router.push({ name: 'Login' })
      } else if (state.initialPath === '/') {
        router.push('/home')
      } else {
        router.push(state.initialPath)
      }
    },

    setCostsNames (state, payload) {
      state.costsNames = payload
    },

    setCosts (state, payload) {
      state.costs = payload
    }
  },

  actions: {
    async check () {
      await auth.check()
    },

    async getCostsNames ({ commit, state }) {
      try {
        const names = await cost.getCostsNames()
        return commit('setCostsNames', names)
      } catch (error) {
        console.error(error)
        return ''
      }
    },

    async getCost ({ commit, state }, name) {
      try {
        const costs = await cost.readCost(name)
        return costs
      } catch (error) {
        console.error({ error })
        return ''
      }
    },

    async getCostTitle ({ commit, state, dispatch }, name) {
      if (state.costsNames.length === 0) {
        await dispatch('getCostsNames')
      }
      return getName(state.costsNames, name)
    }
  }

  // getters: {
  //   costName: (state, name) => {

  //     const cost = state.costsNames.find(n => n.table === name)
  //     return cost.name
  //   }
  // }
})

if (window.Cypress) {
  window.__store__ = store
}

export default store
