<template>
  <router-view />
</template>

<script>
import { onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

export default {
  setup () {
    const store = useStore()
    const route = useRoute()

    onMounted(async () => {
      try {
        await store.dispatch('check')
      } catch (error) {
        store.commit('setAdmin')
      }
    })

    watch(() => route.path, () => {
      document.title = `Veturi - ${route.name}`
    })
  }
}
</script>
